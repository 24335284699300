@use '@edwin-edu/ui/vars' as *;

.heading {
  font-weight: 600;
  color: $color-base-300;

  @include h2();
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0.5rem 1.25rem 1.5rem;
  background-image: linear-gradient(90.7deg, #ffffff 46.73%, rgba(255, 255, 255, 0.4) 106.42%);
  backdrop-filter: blur(20px);
  border-radius: 5px;
  z-index: 0;

  // TODO: make mixin from GradientBorderStyles
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    inset: 0;
    padding: 1.5px;
    border-radius: 5px;
    pointer-events: none;
    background: linear-gradient(93.02deg, rgba(1, 122, 204, 0.36) 23.24%, rgba(255, 255, 255, 0.29) 79.51%);
    // TODO: getting lint errors from this old css?
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
}

.colHeading {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.colContent {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 18px;

  & > li {
    margin: 0.5rem 0;
  }

  @include scrollbar;
}

ol.colContent {
  list-style: decimal;
  list-style-position: inside;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 5px;
  margin-left: 0;
  box-sizing: border-box;
}
